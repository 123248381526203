<template>
	<div
		class="wrap__content"
		v-bind:class="{
			'wrap__content--preloader': !applications || !_getPools || !_getRegionList,
		}"
	>
		<v-preloader
			v-if="!applications || !_getPools || !_getRegionList"
			:message="errorMessage"
		></v-preloader>

		<div class="container" v-if="applications && _getPools && _getRegionList">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['QuotaManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__page--back">
										<a @click="$router.go(-1)">
											<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.125 5.25l-5.25 5.25 5.25 5.25" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</a>
									</div>
									<div class="content__title--element">
										<div class="content__title--text">Редактирование квоты</div>
										<div class="content__title--subtext">
											{{ applications.name }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<form method="POST" @submit.prevent="onSubmit">
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">Наименование квоты</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.name
																? 'input--error'
																: '',
														]"
													>
														<input
															type="text"
															class="input-linck"
															required=""
															name="name"
															v-model="applications.name"
															:placeholder="$t('headers.name')"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.name"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.name"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">{{ $t('inputs.region') }}</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div class="form__line--input">
														<input
															type="text"
															class="input-linck"
															required=""
															disabled
															:value="applications.region.value"
															:placeholder="$t('headers.name')"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">Наименование Лимита</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div class="form__line--input">
														<div type="text" class="input-linck" disabled>
															Лимит от {{ applications.limit.created_at | formatOnlyDate }}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left"></div>
										<div class="form__line--right">
											<div class="input__row">
												<a
													class="input-btn form__line--btn form__line--btn-blue"
													@click="$modal.show('modal_head')"
													v-if="applications.status != 'active' && applications.status != 'deleted'"
												>
													Активировать
												</a>
												<button
													class="input-btn form__line--btn"
													v-if="applications.status != 'active' && applications.status != 'deleted'"
												>
													{{ $t('button.save') }}
												</button>
												<a
													class="input-btn form__line--btn form__line-error"
													@click="$modal.show('modal_delete')"
													v-if="applications.status != 'active' && applications.status != 'deleted'"
												>
													{{ $t('button.deactivate') }}
												</a>
												<!--
                        <a class="input-btn form__line--btn form__btn--border form__line--btn-blue">
                          Отправить в ГО
                        </a>
                        <a class="input-btn form__line--btn form__btn--border" >
                          Отправить в МИО
                        </a>
                        -->
											</div>

											<div
												class="form__block--line form__messages"
												v-if="message.status"
												:class="{
													'form__messages--error': message.status != 200,
													'form__messages--success': message.status == 200,
												}"
											>
												<label class="form__block--input">
													<span class="form__block--title">
														{{ message.text }}
													</span>
												</label>
											</div>
											<div
												class="form__block--line form__messages"
												v-if="errorMessage.text"
												:class="{
													'form__messages--error': errorMessage.status != 200,
													'form__messages--success': errorMessage.status == 200,
												}"
											>
												<label class="form__block--input">
													<span class="form__block--title">
														{{ errorMessage.text }}
													</span>
												</label>
											</div>
										</div>
									</div>
								</form>

								<modal
									class="modal__block modal__block--middle modal__block--success"
									name="modal_head"
									:width="550"
									:min-width="550"
									height="auto"
								>
									<div class="modal__block--wrapper">
										<div class="modal__block--close" @click="$modal.hide('modal_head')">
											<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</div>
										<div class="modal__wrapper">
											<div class="modal__header">
												<div class="modal__title">
													Для утверждения квоты, необходимо подтверждение электронно цифровой
													подписью
												</div>
											</div>

											<div class="modal__content">
												<div class="modal__form">
													<div class="block__row">
														<div class="block__row--left">
															<a
																class="block__item block__item--green"
																@click="unblockElement(applications.id)"
															>
																<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 100 16.666z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.5 10l1.667 1.667L12.5 8.333" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
																Подписать ЭЦП
															</a>
														</div>
														<div class="block__row--right">
															<a
																class="block__item block__item--blue"
																@click="$modal.hide('modal_head')"
															>
																{{ $t('button.cancel') }}
															</a>
														</div>
													</div>
													<div
														class="form__block--line form__messages"
														v-if="message.status"
														:class="{
															'form__messages--error': message.status != 200,
															'form__messages--success': message.status == 200,
														}"
													>
														<label class="form__block--input">
															<span class="form__block--title">
																{{ message.text }}
															</span>
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</modal>
								<modal
									class="modal__block modal__block--middle modal__block--delete"
									name="modal_delete"
									:width="550"
									:min-width="550"
									height="auto"
								>
									<div class="modal__block--wrapper">
										<div class="modal__block--close" @click="$modal.hide('modal_delete')">
											<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</div>
										<div class="modal__wrapper">
											<div class="modal__header">
												<div class="modal__title">Вы уверены что хотите заблокировать квоту?</div>
											</div>

											<div class="modal__content">
												<div class="modal__form">
													<div class="block__row">
														<div class="block__row--left">
															<a class="block__item" @click="blockElement(applications.id)">
																<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.024 12.976l4.952-4.952m0 4.952L8.024 8.024M10.5 19.25c4.813 0 8.75-3.938 8.75-8.75 0-4.813-3.938-8.75-8.75-8.75-4.813 0-8.75 3.938-8.75 8.75 0 4.813 3.938 8.75 8.75 8.75z" stroke="#DF4242" stroke-linecap="round" stroke-linejoin="round"/></svg>
																{{ $t('button.deactivate') }}
															</a>
														</div>
														<div class="block__row--right">
															<a
																class="block__item block__item--blue"
																@click="$modal.hide('modal_delete')"
															>
																{{ $t('button.cancel') }}
															</a>
														</div>
													</div>
													<div
														class="form__block--line form__messages"
														v-if="message.text"
														:class="{
															'form__messages--error': message.status != 200,
															'form__messages--success': message.status == 200,
														}"
													>
														<label class="form__block--input">
															<span class="form__block--title">
																{{ message.text }}
															</span>
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</modal>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { api } from '@/boot/axios'

export default {
	data() {
		return {
			applications: null,

			regionValue: null,
			poolValue: null,
			reservoirsValue: null,

			quotaFish: [],
			quotaValue: [],
			quotaSum: [],
			finalSum: 0,

			fildsNew: '',
			fildsList: [],
			fildsIndex: 0,

			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},
		}
	},

	computed: {
		...mapGetters(['_getRegionList', '_getPondList', '_getPools', '_getFishes']),
		formList() {
			let regionList = this._getRegionList.filter((element) => {
				if (this.poolValue && this.poolValue.links) {
					return this.poolValue.links.find((selected) => {
						return element.id == selected.item_id ? true : false
					})
				}
				return true
			})

			let list = {
				pool: this._getPools,
				region: regionList,
			}
			return list
		},
	},
	methods: {
		...mapActions(['_fetchRegionList', '_fetchPondList', '_fetchPools', '_fetchFishes']),
		unblockElement(id) {
			if (this.$configFile.active) return this.sendEcpKey(id)
			this.ecp = false
			const websocket = new WebSocket('wss://127.0.0.1:13579/')
			var ready = null
			let ecpData = {
				path: null,
				owner: null,
				data: null,
			}
			websocket.onopen = () => {
				ready = true

				handle()
			}
			websocket.onclose = (e) => {
				if (e.wasClean) {
				} else {
					if (this.$i18n.locale == 'kz')
						alert('NCYLayer бағдарламасы іске қосылғандығына көз жеткізіңіз')
					if (this.$i18n.locale == 'ru') alert('Убедитесь, что программа NCALayer запущена')
					if (this.$i18n.locale == 'en') alert('Make sure that the NCALayer program is running')

					//setErrormodal(true);
					setTimeout(() => {
						//setErrormodal(false);
					}, 5000)
				}
			}
			function handle() {
				if (!ready) {
					alert('Убедитесь, что программа NCALayer запущена')
				} else {
					const data = {
						module: 'kz.gov.pki.knca.commonUtils',
						method: 'signXml',
						args: [
							'PKCS12',
							'SIGNATURE',
							'<login><sessionid>caacda70-fd36-45ed-8d94-45a88890f83a</sessionid></login>',
							'',
							'',
						],
					}
					websocket.send(JSON.stringify(data))
				}
			}
			websocket.onmessage = (e) => {
				const data1 = JSON.parse(e.data)
				ecpData.data = data1.responseObject
				this.dataEcp = data1.responseObject
				this.sendEcpKey(id)
			}
		},
		blockElement(id) {
			api
				.post('quota/' + id + '/deactivate', {
					data: this.dataEcp || this.$configFile.xml,
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message

						this.$router.push({ path: '/' + this.$i18n.locale + '/account/quotas' })
					}
				})
		},
		sendEcpKey(id, send) {
			this.message.status = null
			this.message.text = null
			this.message.texts = null

			api
				.post('quota/' + id + '/activate', {
					data: this.dataEcp || this.$configFile.xml,
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message

						this.$router.push({ path: '/' + this.$i18n.locale + '/account/quotas' })
					}
				})
		},
		apiGetQuota() {
			api
				.get('quota/' + this.$route.params.id)
				.then((response) => {
					if (response.data) {
						this.applications = response.data
					}
				})
				.catch((error) => {
					if (error.response) {
						if (error?.response?.status == 500) {
							this.errorMessage.status = 500
							this.errorMessage.text = this.$t('system_message.500')
						}
						if (error?.response?.status == 401) {
							this.errorMessage.status = 401
							this.errorMessage.text = error.response.data.error_message
							this.$router.push('/' + this.$i18n.locale + '/login')
						}
						if (error?.response?.status == 422) {
							this.errorMessage.status = 422
							this.errorMessage.text = error.response.data.error_message
						}
						if (error?.response?.status == 403) {
							this.errorMessage.status = 403
							this.errorMessage.text = error?.response?.data?.message
						}
					}
				})
		},

		onSubmit(evt) {
			this.errorMessage = {
				status: null,
				messages: null,
				text: null,
			}
			this.message = {
				status: null,
				text: null,
			}
			const formData = new FormData(evt.target)
			formData.append('region_id', 15) //this.pondModel.id

			if (this.reservoirsValue) {
				formData.append('pond_id', this.reservoirsValue.id)
			}

			api
				.post('quota/' + this.$route.params.id, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message
						// this.$router.push({path: '/'+ this.$i18n.locale +'/account/quotas'});
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					this.errorMessage.messages = error.response.data.error_messages
				})
		},
	},
	beforeMount() {
		this._fetchPondList()
		this._fetchPools()
		this._fetchRegionList()
		this._fetchFishes()
		this.apiGetQuota()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
}
</script>
